import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import * as loadingActions from '@/actions/loading'
import style from './style'

gsap.registerPlugin(DrawSVGPlugin)

const Loader = ({ classes }) => {
  const $root = useRef()
  const $logo = useRef()
  const $spinner = useRef()
  const $logoWrapper = useRef()
  const $logoSymbol = useRef()
  const [isStrokeAnimationFinished, setStrokeAnimationFinished] = useState(false)
  const [isLogoAnimationFinished, setLogoAnimationFinished] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isSiteLoaded, isLoaderExited } = useSelector((state) => ({
    isSiteLoaded: state.loading.isSiteLoaded,
    isLoaderExited: state.loading.isLoaderExited,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLoaderExited = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoaderExited', bool)), [dispatch])

  /*------------------------------
  On Complete Animation
  ------------------------------*/
  const onCompleteLoaderEnd = useCallback(() => setLoaderExited(true), [])
  const onCompleteStrokeAnimation = useCallback(() => setStrokeAnimationFinished(true), [])

  /*------------------------------
  Loader End Animation
  ------------------------------*/
  const animateLoaderEnd = useCallback(() => {
    const timeline = gsap.timeline({ onComplete: onCompleteLoaderEnd })
    timeline
      .to([$logo.current, $spinner.current], ({
        autoAlpha: 0,
      }), 1.2)
      .to($root.current, ({
        autoAlpha: 0,
      }), 2)
  }, [])

  const animateIn = useCallback(() => {
    gsap.set([$logoSymbol.current.children, $logoWrapper.current], { drawSVG: '0%' })
    gsap.set($logo.current, { scale: 1.2 })
    const timeline = gsap.timeline({ onComplete: onCompleteStrokeAnimation })
    timeline
      .to($logoWrapper.current, {
        duration: 1.2,
        drawSVG: '100%',
        immediateRender: false,
      })
      .to($logoSymbol.current.children, {
        duration: 1.2,
        drawSVG: '100%',
        immediateRender: false,
        stagger: 0.2,
      }, 0)
      .to($logo.current, {
        duration: 1.2,
        scale: 1,
      }, 0)
  }, [])

  /*------------------------------
  Animate Loader End
  ------------------------------*/
  useEffect(() => {
    if (isSiteLoaded && isLogoAnimationFinished) animateLoaderEnd()
  }, [isSiteLoaded, isLogoAnimationFinished])

  const handleTransitionEnd = useCallback(() => setLogoAnimationFinished(true), [])

  useEffect(() => {
    animateIn()
    $logo.current.addEventListener('transitionend', handleTransitionEnd)
    return () => {
      if ($logo.current) $logo.current.removeEventListener('transitionend', handleTransitionEnd)
    }
  }, [])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: isLoaderExited,
      })}
      ref={$root}
    >
      <div className={classes.logoContainer}>
        <svg
          className={classNames({
            [classes.logo]: true,
            [classes.logoFilled]: isStrokeAnimationFinished,
          })}
          ref={$logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          width="50"
          height="50"
        >
          <path ref={$logoWrapper} d="M25,1h0A24,24,0,1,0,49,25.1h0V1Z" />
          <g ref={$logoSymbol}>
            <path d="M22.86,41.65a10.4,10.4,0,0,1-7.38-3,11,11,0,0,1-2.91-7.45v-22h7.55v5.73h7v7.53h-7v8.84a3.45,3.45,0,0,0,.77,2.06A3.27,3.27,0,0,0,23,34h4.25v7.63H22.86Z" />
            <path d="M30.78,20h7.59V30.93a10.51,10.51,0,0,1-4.92,9.36l-2.66,1.62Z" />
            <path d="M30.79,17.3V9.56h7.58V17.3Z" />
          </g>
        </svg>
      </div>
      <div
        className={classNames({
          [classes.spinnerContainer]: true,
          [classes.spinnerContainerVisible]: isStrokeAnimationFinished,
        })}
      >
        <svg
          ref={$spinner}
          className={classes.spinner}
          viewBox="0 0 50 50"
          width="50"
          height="50"
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </svg>
      </div>
    </div>
  )
}

export default injectSheet(style)(memo(Loader))
