const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      width: '45vw',
      transform: 'translate(-50%, -50%)',
      background: theme.colors[1],
      borderRadius: 22,
      zIndex: theme.zindex.modal,
    },
    close: {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: 20,
      right: 20,
      width: 41,
      height: 41,
      borderRadius: '50%',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderRadius: '50%',
        background: theme.colors[4],
        width: '100%',
        height: '100%',
        transition: `all 1s ${theme.easings['power3.out']}`,
      },
      '@media (hover:hover)': {
        '&:hover': {
          background: theme.colors[3],
          '&:before': {
            transform: 'scale(1.2)',
            background: theme.colors[3],
          },
        },
      },
    },
    wrapper: {
      height: '100%',
      position: 'relative',
      margin: 0,
      color: theme.colors[4],
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
      paddingLeft: '4.16vw',
      paddingRight: '4.16vw',
      paddingTop: 40,
      paddingBottom: 40,
    },
    scrollable: {
      width: '100%',
      '& > *': {
        marginBottom: 80,
      },
    },
    visible: {
      opacity: 1,
    },
    title: {
      fontSize: 40,
      fontWeight: 300,
      fontFamily: theme.fonts[1],
    },
  }

  output[theme.mediaInverse.lg] = {
    root: {
      width: '70vw',
    },
  }

  output[theme.mediaInverse.sm] = {
    root: {
      width: '100%',
      top: 0,
      left: 0,
      height: '100%',
      transform: 'translate(0, 0)',
      borderRadius: 0,
    },
    title: {
      fontSize: 32,
    },
  }

  return output
}

export default style
