const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 20,
      marginLeft: -10,
      marginRight: -10,
    },
    formControl: {
      width: '50%',
      padding: '0 10px',
      marginBottom: 30,
      position: 'relative',
      zIndex: 1,
    },
    formControlTextarea: {
      width: '100%',
      marginBottom: 30,
    },
    formControlCheckbox: {
      width: '100%',
      marginBottom: 30,
    },
    formControlSelect: {
      width: '100%',
      marginBottom: 30,
      zIndex: 2,
    },

    // Generic Input
    input: {
      width: '100%',
      color: theme.colors[3],
      appearance: 'none',
      padding: '16px 28px',
      fontSize: 16,
      borderRadius: 50,
      fontWeight: 300,
      fontFamily: theme.fonts[0],
      background: theme.getRgba(theme.colors[4], 0.2),
      border: '2px solid transparent',
      transition: 'border 0.2s ease-in',
      '&::placeholder': {
        color: theme.colors[4],
      },
    },

    // Textarea
    textarea: {
      width: '100%',
      color: theme.colors[3],
      background: theme.getRgba(theme.colors[4], 0.2),
      appearance: 'none',
      padding: '16px 28px',
      fontSize: 16,
      fontFamily: theme.fonts[0],
      fontWeight: 300,
      borderRadius: 20,
      resize: 'none',
      border: '2px solid transparent',
      transition: 'border 0.3s ease-in',
      '&::placeholder': {
        color: theme.colors[4],
      },
    },

    // Select
    select: {
      '& .customSelect__control': {
        color: theme.colors[3],
        background: theme.getRgba(theme.colors[4], 0.2),
        fontSize: 16,
        fontFamily: theme.fonts[0],
        borderRadius: 10,
        padding: '8px 28px',
        border: '2px solid transparent',
      },
      '& .customSelect__placeholder': {
        color: theme.getRgba(theme.colors[0], 0.3),
      },
      '& .customSelect__menu': {
        color: theme.colors[2],
        background: theme.colors[7],
        fontSize: 16,
        fontFamily: theme.fonts[0],
        borderRadius: 10,
        border: 'none',
      },
      '& .customSelect__value-container': {
        padding: 0,
      },
      '& .customSelect__indicator-separator': {
        display: 'none',
      },
      '& .customSelect__control--is-focused': {
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      '& .customSelect__option--is-focused': {
        background: '#fae189',
      },
      '& .customSelect__option--is-selected': {
        background: theme.colors[3],
      },
    },

    // Checkbox
    checkbox: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      fontSize: 11,
      '& input[type="checkbox"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
      },
      '& input[type="checkbox"] + span': {
        display: 'inline-block',
        paddingLeft: 22,
        position: 'relative',
        zIndex: 1,
        '& a': {
          color: 'currentColor',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          top: 0,
          left: 0,
          width: 13,
          height: 13,
          borderRadius: 10,
          border: `1px solid ${theme.getRgba(theme.colors[4], 0.2)}`,
          background: theme.getRgba(theme.colors[4], 0.2),
          transition: 'all .2s ease-in-out',
        },
      },
      '& input[type="checkbox"]:checked + span': {
        '&:before': {
          background: theme.colors[3],
          boxShadow: `inset 0 0 0 2px ${theme.colors[4]}`,
        },
      },
    },

    // Labels
    label: {
      '& a': {
        display: 'inline-block',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 1,
          width: '100%',
          background: 'currentColor',
          transition: 'all .3s ease-out',
          transformOrigin: 'left center',
        },
        '&:hover': {
          '&::before': {
            animation: '$underline .6s forwards',
          },
        },
      },
    },
    '@keyframes underline': {
      '0%': { transform: 'scaleX(0)' },
      '100%': { transform: 'scaleX(1)' },
    },

    // File Field
    formControlUpload: {
      width: '100%',
      marginBottom: 30,
      '& input': {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      },
      '& svg': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        right: 40,
        width: 13,
        height: 13,
        fill: theme.colors[5],
      },
      '& label': {
        display: 'block',
        cursor: 'pointer',
        width: '100%',
        color: theme.getRgba(theme.colors[0], 0.3),
        appearance: 'none',
        padding: '16px 28px',
        fontSize: 16,
        fontFamily: theme.fonts[0],
        border: '2px solid transparent',
        background: theme.getRgba(theme.colors[2], 0.05),
        borderRadius: 10,
        transition: 'all .3s ease-out',
        userSelect: 'none',
        '& span': {
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          verticalAlign: 'top',
          width: '100%',
        },
      },
    },
    formControlIsFill: {
      '& label': {
        color: theme.colors[2],
      },
    },

    // Errors
    formError: {
      '& input': {
        borderColor: '#cc0000',
      },
      '& input[type="checkbox"] + span': {
        '&:before': {
          borderColor: '#cc0000',
        },
      },
      '& .customSelect__control': {
        borderColor: '#cc0000',
      },
      '&$formControlUpload': {
        '& label': {
          borderColor: '#cc0000',
        },
      },
    },

    // Submit Button
    submit: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      cursor: 'pointer',
      fontSize: 14,
      color: theme.colors[3],
      '&::after': {
        content: '""',
        width: 34,
        height: 34,
        background: theme.colors[4],
        borderRadius: '50%',
        marginLeft: 10,
        transition: 'all 0.3s ease-out',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        right: 14,
        zIndex: 1,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '3.5px 0 3.5px 4px',
        borderColor: `transparent transparent transparent ${theme.colors[1]}`,
        transition: 'all 0.3s ease-out',
      },
      '&:hover': {
        '&::after': {
          background: theme.colors[7],
        },
        '&::before': {
          borderColor: `transparent transparent transparent ${theme.colors[3]}`,
        },
      },
    },

    // Result Message
    result: {
      marginTop: 30,
      marginBottom: 0,
      fontSize: 14,
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
    },

    // Error Field
    error: {
      position: 'absolute',
      right: 10,
      bottom: -15,
      fontSize: 10,
      color: '#cc0000',
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
    },

    visible: {
      opacity: 1,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    formControl: {
      width: '100%',
    },
  }

  return output
}

export default style
