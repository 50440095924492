import { memo, forwardRef } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import style from './style'

const useStyles = createUseStyles(style)

const Logo = forwardRef(({
  tabIndex,
  className,
}, ref) => {
  const classes = useStyles()
  const theme = useTheme()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentLanguage } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
      ref={ref}
    >
      <DelayLink
        to={`/${currentLanguage}`}
        className={classes.logo}
        tabIndex={tabIndex}
        aria-label="Home"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 501.42 156.41"
          width={theme.header.logo.width}
          height={theme.header.logo.height}
          fill={theme.header.logo.fill}
        >
          <path d="M54.83 0h-.07a54.77 54.77 0 1054.76 55V0zm2.68 90.45h-6.59c-6.8 0-12.28-2.11-16.29-6.26-5.79-6-6-13.88-6-15.39v-48h12.71v13.09h16v12.63h-16V69c0 .23.09 4 2.41 6.32 1.53 1.57 3.94 2.35 7.17 2.35h6.59zM83 69.22c0 8.51-3.6 15.42-10.16 19.42l-2.57 1.57V46.57H83zm0-34.31H70.24V21.8H83zM151.69 87c-5.25 0-7-2-7.86-3.44a10.43 10.43 0 01-1.27-4.56V43.14h22.29V27.7h-22.29V5.76H127.1v73.12c0 1.48.19 10.16 6.62 16.74 3 3.11 8.63 6.82 17.95 6.82H165V87zM197.15 21.2h13.34V5.76h-13.34c-9.31 0-14.9 3.71-17.94 6.82-6.43 6.58-6.64 15.26-6.63 16.75V79c0 1.47.2 10.16 6.62 16.72 3 3.11 8.63 6.82 18 6.82h13.34V87.1h-13.39c-5.24 0-7-2-7.86-3.44a10.24 10.24 0 01-1.29-4.52V61.21h18.25V45.77H188V29.16a10.32 10.32 0 011.27-4.52c.85-1.47 2.61-3.44 7.85-3.44M242.17 21.27h13.34V5.83h-13.34c-9.31 0-14.88 3.71-17.93 6.82-6.45 6.59-6.65 15.32-6.63 16.79v49.49c0 1.47.18 10.19 6.63 16.79 3 3.11 8.62 6.82 17.93 6.82h13.34V87.1h-13.34c-5.24 0-7-2-7.85-3.46a10.63 10.63 0 01-1.32-4.57V29.28a10.51 10.51 0 011.28-4.55c.86-1.48 2.61-3.46 7.85-3.46M320.06 5.84h15.44v15.35h-15.44z" />
          <path d="M391.87 62.53c0-1.46-.18-10.13-6.6-16.69" />
          <path d="M385.27 45.84c-3-3.11-8.6-6.81-17.89-6.81H345.55l-.05 63.5h15.3V54.29h6.55c5.29 0 7.05 2 7.92 3.48a10.66 10.66 0 011.28 4.58v40.14h15.3v-40M450.09 102.58h17.74l7.75-15.64 7.69 15.64h17.75l-16.57-32 16.43-31.47h-17.75l-7.64 14.84-7.99-14.84h-17.75l16.97 31.47-16.63 32z" />
          <path d="M435.16 38.87H414.4a12.88 12.88 0 00-12.87 12.87v38.1a12.89 12.89 0 0012.87 12.87h20.76A12.88 12.88 0 00448 89.84v-38.1a12.88 12.88 0 00-12.86-12.87m-18.4 47.2V55.69a1.23 1.23 0 011.34-1.18h13.25a1.21 1.21 0 011.27 1.12v30.45a1.23 1.23 0 01-1.26 1.19H418a1.22 1.22 0 01-1.26-1.2M310 102.83a21.08 21.08 0 01-15.44-20.08V29.43a10.87 10.87 0 00-1.28-4.61c-.86-1.48-2.6-3.47-7.83-3.47h-5.6v81.28h-15.41V5.91h21.05c9.29 0 14.87 3.72 17.91 6.82 6.44 6.61 6.65 15.36 6.63 16.86v73.24" />
          <path d="M320 102.86V39h15.37v43.52A21.14 21.14 0 01320 102.86" />
          <path id="group" d="M424.22 112.28h-10.37l-4.55 4.13.71-4.13h-6.72l-5.3 31.16h6.73l4.29-25.29h8.69l6.52-5.87zM398.81 112.28h-6.72l-.85 5-2.94-5h-6.81a6.82 6.82 0 00-7.08 6.09l-3.22 19c-.69 4 1.45 6.1 5 6.1h6.75l4.7-5-2 11.77h-14.15l-1.05 6.18h13c3.55 0 8.54-4.12 9.23-8.15zm-20.93 25.23l3.29-19.31h9.91l-3.28 19.31zM474.83 112.28h-6.72l-4.29 25.17h-9.91l4.28-25.17h-6.73l-4.26 25.06c-.68 4 1.46 6.1 5 6.1h6.8l4.63-4.92-.83 4.92h6.72zM446 118.37c.69-4-1.45-6.09-5-6.09h-10.43a6.81 6.81 0 00-7.08 6.09l-3.23 19c-.68 4 1.46 6.1 5 6.1h10.38a6.83 6.83 0 007.09-6.1zm-19 19.08l3.27-19.25h9L436 137.45zM501.29 118.37c.69-4-1.45-6.09-5-6.09h-6.57l-4.59 4.72.8-4.69h-6.73l-7.2 42.04h6.73l2.69-15.83 3 4.92H491a6.83 6.83 0 007.08-6.1zm-19.65 19.08l3.28-19.25h9.67l-3.27 19.25z" />
        </svg>
      </DelayLink>
    </div>
  )
})

export default memo(Logo)
