const style = (theme) => {
  const output = {
    logo: {
      display: 'block',
      lineHeight: 0,
      '& #group': {
        fill: theme.colors[4],
      },
    },
  }

  return output
}
export default style
