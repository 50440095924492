const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[1],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hide: {
      pointerEvents: 'none',
    },
    logoContainer: {
      width: 50,
      height: 50,
    },
    logo: {
      width: '100%',
      height: '100%',
      stroke: theme.colors[3],
      strokeWidth: 1.5,
      fill: 'transparent',
      transition: `all 0.75s ${theme.easings.garret}`,
      '& g': {
        fill: theme.colors[1],
      },
    },
    logoFilled: {
      fill: theme.colors[3],
    },
    spinnerContainer: {
      width: 150,
      height: 150,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transition: `all 0.75s ${theme.easings.garret}`,
      opacity: 0,
    },
    spinnerContainerVisible: {
      opacity: 1,
    },
    spinner: {
      width: 150,
      height: 150,
      transformOrigin: 'center',
      animation: '$rotate 2s linear infinite',
      transition: 'all 0.3s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      '& .path': {
        stroke: theme.colors[3],
        strokeWidth: 0.6,
        fill: 'none',
        animation: '$dash 2.5s ease-in-out infinite',
      },
    },
    '@keyframes rotate': {
      from: { transform: 'rotate(0)' },
      to: { transform: 'rotate(360deg)' },
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: 0,
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -35,
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -124,
      },
    },
  }
  return output
}

export default style
