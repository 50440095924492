import { useState, useEffect, useRef } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import { Formik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { createYupSchema, createInitialValues } from '@/utils/form'
import TextField from '@/components/WpContactForm7/Fields/TextField'
import TextareaField from '@/components/WpContactForm7/Fields/TextareaField'
import SelectField from '@/components/WpContactForm7/Fields/SelectField'
import FileField from '@/components/WpContactForm7/Fields/FileField'
import CheckboxField from '@/components/WpContactForm7/Fields/CheckboxField'
import { CONFIG } from '@/constants'

import style from './style'

const WpContactForm7 = ({
  onSuccessSend,
  classes,
  fields,
  formID,
  sendContactForm,
  submitLabel,
  hiddenValues,
}) => {
  const [state, setState] = useState(null || '')
  const formSchema = useRef({})
  const initialValues = useRef({})
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [init, setInit] = useState(true)
  const [token, setToken] = useState('')
  const [isMultipart, setMultipart] = useState(false)

  useEffect(() => {
    formSchema.current = createYupSchema(fields)
    initialValues.current = createInitialValues(fields)

    // Check if is Multipart
    setMultipart(fields.some((field) => field.type === 'file'))
  }, [])

  useEffect(() => {
    // Execute Recaptcha
    if (process.env.NODE_ENV === 'production' && init && CONFIG.RECAPTCHA_SITE_KEY.length > 0) {
      const result = executeRecaptcha('contact_page')
      result.then((response) => {
        setToken(response)
        setInit(false)
      })
    }
  }, [init])

  return fields.length > 0 && (
    <>
      <Formik
        initialValues={initialValues.current}
        validationSchema={formSchema.current}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = { ...values, _wpcf7_recaptcha_response: token, ...hiddenValues }
          const result = sendContactForm(formID, data, isMultipart)

          result
            .then((response) => {
              if (response.data.status === 'mail_sent') {
                onSuccessSend()
              }
              setState(response.data.message)
              setSubmitting(false)
            })
            .then(() => {
              setTimeout(() => {
                resetForm()
                setInit(true) // Reset Recaptcha Token
                setState('')
              }, 1500)
            })
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, submitCount, setFieldValue }) => {
          return (
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {fields.map((field, i) => {
                if (field.type === 'text' || field.type === 'tel' || field.type === 'email' || field.type === 'url' || field.type === 'number' || field.type === 'date') {
                  return (
                    <TextField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.placeholder || field.label} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                    />
                  )
                }
                if (field.type === 'textarea') {
                  return (
                    <TextareaField
                      key={i.toString()}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.placeholder || field.label} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                    />
                  )
                }
                if (field.type === 'select') {
                  return (
                    <SelectField
                      key={i.toString()}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      options={field.options}
                      includeBlank={field.include_blank}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'file') {
                  return (
                    <FileField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'checkbox') {
                  return (
                    <CheckboxField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                return null
              })}
              <button
                className={classes.submit}
                type="submit"
                disabled={isSubmitting}
                aria-label="submit-form"
              >
                {submitLabel}
              </button>
            </form>
          )
        }}
      </Formik>
      <p
        className={classNames({
          [classes.result]: true,
          [classes.visible]: state !== '',
        })}
      >
        {state}
      </p>
    </>
  )
}

WpContactForm7.defaultProps = {
  submitLabel: 'submit',
  onSuccessSend: () => {},
  hiddenValues: {},
}

export default injectSheet(style)(WpContactForm7)
