import { lazy } from 'react'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'

const Home = lazy(() => import(/* webpackChunkName: "home" */'@/pages/Home'))
const Contact = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Contact'))
const About = lazy(() => import(/* webpackChunkName: "about" */'@/pages/About'))
const Group = lazy(() => import(/* webpackChunkName: "group" */'@/pages/Group'))
const Quality = lazy(() => import(/* webpackChunkName: "quality" */'@/pages/Quality'))
const Research = lazy(() => import(/* webpackChunkName: "research" */'@/pages/Research'))
const ArchiveProducts = lazy(() => import(/* webpackChunkName: "archive_products" */'@/pages/ArchiveProducts'))
const Product = lazy(() => import(/* webpackChunkName: "product" */'@/pages/Product'))
const ArchiveNews = lazy(() => import(/* webpackChunkName: "archive_news" */'@/pages/ArchiveNews'))
const Post = lazy(() => import(/* webpackChunkName: "post" */'@/pages/Post'))
const Download = lazy(() => import(/* webpackChunkName: "download" */'@/pages/Download'))
const NotFound = lazy(() => import(/* webpackChunkName: "not_found" */'@/pages/NotFound'))

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact />,
  },
  {
    key: 2,
    path: internazionalizePath(
      getPathFromCpt(routing, 'post'),
    ),
    render: () => <Post />,
  },
  {
    key: 3,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_news'),
    ),
    render: () => <ArchiveNews />,
  },
  {
    key: 4,
    path: internazionalizePath(
      getPathFromCpt(routing, 'group'),
    ),
    exact: true,
    render: () => <Group />,
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'about'),
    ),
    exact: true,
    render: () => <About />,
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'research'),
    ),
    exact: true,
    render: () => <Research />,
  },
  {
    key: 7,
    path: internazionalizePath(
      getPathFromCpt(routing, 'quality'),
    ),
    exact: true,
    render: () => <Quality />,
  },
  {
    key: 8,
    path: internazionalizePath(
      getPathFromCpt(routing, 'download', 'stub'),
    ),
    exact: true,
    render: () => <Download />,
  },
  {
    key: 9,
    path: internazionalizePath(
      getPathFromCpt(routing, 'download'),
    ),
    exact: true,
    render: () => <Download />,
  },
  {
    key: 10,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cty_product'),
    ),
    exact: true,
    render: () => (
      <Product
        cpt={routing.find((r) => r.cpt === '_cty_product').cpt}
      />
    ),
  },
  {
    key: 11,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cta_category'),
    ),
    exact: true,
    render: () => (
      <ArchiveProducts
        cpt={routing.find((r) => r.cpt === '_cta_category')?.term}
        taxonomy={routing.find((r) => r.cpt === '_cta_category')?.cpt}
      />
    ),
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
