import { memo, useEffect, useRef, useLayoutEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import WpContactForm7 from '@/components/WpContactForm7'
import usePrevious from '@/hooks/usePrevious'
import * as layerActions from '@/actions/layer'
import * as formActions from '@/actions/form'
import style from './style'

const useStyles = createUseStyles(style)

const ModalDownload = () => {
  const $root = useRef()
  const [ready, setReady] = useState(false)
  const classes = useStyles()
  const formId = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentForm, pathname, strings, isDownloadFormOpen, currentItem } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    strings: state.options.strings,
    currentForm: state.form[formId.current] || {},
    currentItem: state.gdrive.currentItem,
    isDownloadFormOpen: state.layer.layers.some((layer) => layer.id === 'download' && layer.isOpen),
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: 'download' })), [dispatch])
  const fetchForm = useCallback((id) => dispatch(formActions.fetchForm(id)), [dispatch])
  const sendContactForm = useCallback((id, data, isMultipart) => dispatch(formActions.sendContactForm(id, data, isMultipart)), [dispatch])

  /*------------------------------
  Fetch Form
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(strings).length > 0) {
      formId.current = strings.download_form_id
      fetchForm(formId.current)
    }
  }, [strings])

  /*------------------------------
  Set Ready
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(currentForm).length > 0) setReady(true)
  }, [currentForm])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = useCallback(() => {
    gsap.set($root.current, {
      autoAlpha: 0,
      scale: 0.9,
    })
  }, [])

  /*------------------------------
  Manage Esc Key Down
  ------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isDownloadFormOpen) closeLayer()
  }, [isDownloadFormOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isDownloadFormOpen])

  const openSideRight = useCallback(() => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 0.8,
      autoAlpha: 1,
      scale: 1,
      ease: 'elastic.out',
    })
  }, [])

  const closeSideRight = useCallback(() => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 1,
      autoAlpha: 0,
      scale: 0.9,
      ease: 'power3.out',
    })
  }, [])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsSideRightOpen = usePrevious(isDownloadFormOpen)
  useEffect(() => {
    if (prevIsSideRightOpen !== undefined && isDownloadFormOpen) openSideRight()
    if (prevIsSideRightOpen !== undefined && !isDownloadFormOpen) closeSideRight()
  }, [isDownloadFormOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (
      prevPathname !== undefined
      && prevPathname !== pathname
    ) {
      setTimeout(() => {
        closeSideRight()
      }, 500)
    }
  }, [pathname])

  /*------------------------------
  Handle Close
  ------------------------------*/
  const handleClose = useCallback(() => closeLayer(), [])

  return ready && (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <button
        className={classes.close}
        onClick={handleClose}
        aria-label="close-modal"
      >
        <svg><use xlinkHref="#ico-close" /></svg>
      </button>
      <div className={classes.wrapper}>
        <div className={classes.scrollable}>
          <div className={classes.title}>{currentForm.title}</div>
          <WpContactForm7
            fields={currentForm.fields}
            formID={formId.current}
            sendContactForm={sendContactForm}
            submitLabel={strings.label_submit}
            hiddenValues={{ file: currentItem.downloadLink }}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(ModalDownload)
