import { memo, useRef, useCallback, useState } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useContext } from 'use-context-selector'
import Logo from '@/components/Logo'
import Burger from '@/components/Burger'
import LangSwitcher from '@/components/LangSwitcher'
import { Context } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf, useResize } from '@/components/Handlers'
import useMediaQuery from '@/hooks/useMediaQuery'
import * as layerActions from '@/actions/layer'
import * as boundsActions from '@/actions/bounds'
import { cleanOrigin } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Header = ({ treshold }) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, strings, languages, layoutStyle, backButton } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    strings: state.options.strings,
    languages: state.locale.languages,
    layoutStyle: state.layout.style,
    backButton: state.layout.backButton,
  }), shallowEqual)

  const { scroll, direction: directionFromContext } = useContext(ScrollbarContext)
  const { backButtonOnClick } = useContext(Context)
  const classes = useStyles({ layoutStyle })
  const [isBurgerButtonHover, setBurgerButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const [direction, setDirection] = useState(true)
  const $root = useRef()
  const history = useHistory()
  const rootBounds = useRef()
  const { match: isMobile } = useMediaQuery('sm')

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])
  const setHeaderBounds = useCallback((bounds) => dispatch(boundsActions.setBoundsElement('header', bounds)), [dispatch])

  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter') setBurgerButtonHover(true)
    if (e.type === 'mouseleave') setBurgerButtonHover(false)
  }

  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setHeaderBounds(rootBounds.current)
    }
  })

  const handleMainMenuClick = useCallback(() => {
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }, [isMenuOpen])

  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  const update = useCallback(() => {
    if (scroll.current.y <= treshold) setTop(true)
    if (scroll.current.y > treshold) setTop(false)
    if (directionFromContext.current) setDirection(directionFromContext.current)
  }, [])

  useRaf(() => {
    update()
  }, [isTop])

  /*------------------------------
  Render Burger
  ------------------------------*/
  const renderBurgerButton = useCallback(() => {
    return (
      <button
        className={classNames({
          [classes.burgerButton]: true,
          [classes.burgerButtonOpen]: isMenuOpen,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={(e) => handleMouseMove(e)}
        onMouseLeave={(e) => handleMouseMove(e)}
      >
        <span
          className={classNames({
            [classes.burgerLabel]: true,
            [classes.hide]: isMenuOpen,
          })}
        >
          {strings.menu_label}
        </span>
        <Burger
          className={classes.burgerIcon}
          isActive={isMenuOpen}
          isHover={isBurgerButtonHover}
        />
      </button>
    )
  }, [strings, isMenuOpen, isBurgerButtonHover])

  /*------------------------------
  Render BackButton
  ------------------------------*/
  const renderBackButton = useCallback(() => {
    return (
      <button
        className={classNames({
          [classes.back]: true,
          [classes.hide]: isMenuOpen,
        })}
        onClick={() => {
          if (backButton.link !== '') {
            history.push(cleanOrigin(backButton.link))
          } else if (backButton.callback) {
            backButtonOnClick()
          } else {
            history.goBack()
          }
        }}
        aria-label="go-back"
        ref={preventPressSpacebar}
      >
        <div className={classes.backArrow} />
        <span>{backButton.label}</span>
      </button>
    )
  }, [isMenuOpen, backButton, backButtonOnClick])

  return (
    <header
      className={classNames({
        [classes.root]: true,
        [classes.rootBackground]: !isTop && !isMenuOpen,
        [classes.rootHide]: !isTop && direction === 'down' && !isMobile,
      })}
      ref={$root}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div
            className={classNames({
              [classes.left]: true,
              [classes.hide]: isMenuOpen,
            })}
          >
            {backButton.visible
              ? renderBackButton()
              : <span className={classes.payOff}>{strings.site_payoff}</span>}
          </div>
          <div
            className={classNames({
              [classes.logo]: true,
              [classes.hide]: isMenuOpen,
            })}
          >
            <Logo />
          </div>
          {languages && Object.keys(languages).length > 1 && (
            <div
              className={classNames({
                [classes.lang]: true,
                [classes.hide]: isMobile ? !isMenuOpen : isMenuOpen,
              })}
            >
              <LangSwitcher />
            </div>
          )}
          <div className={classes.burger}>
            {renderBurgerButton()}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  treshold: 100,
  menuLabel: 'Menu',
  headerNav: [],
}

export default memo(Header)
