const style = (theme) => {
  const transition = `all 1s ${theme.easings['power4.out']}`

  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: theme.zindex.header,
      pointerEvents: 'none',
      background: 'transparent',
      transition: `all 0.5s ${theme.easings.garret}`,
    },
    rootBackground: {
      background: theme.colors[1],
      '& $left': {
        color: () => theme.colors[3],
      },
      '& $logo': {
        '& svg': {
          fill: () => theme.colors[3],
          '& #group': {
            fill: () => theme.colors[4],
          },
        },
      },
      '& $lang': {
        color: () => theme.colors[3],
      },
      '& $burgerLabel': {
        color: () => theme.colors[3],
      },
      '& $burgerIcon': {
        color: () => theme.colors[3],
      },
    },
    rootHide: {
      transform: 'translateY(-100%)',
    },
    container: {
      margin: '0 auto',
      paddingTop: theme.header.top,
      paddingBottom: theme.header.bottom,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition,
      height: `${theme.header.logo.height}px`,
    },
    left: {
      color: (props) => (props.layoutStyle === 'default' ? theme.colors[1] : theme.colors[3]),
      fontSize: 16,
      transition: `opacity 0.3s ${theme.easings.garret}`,
    },
    logo: {
      position: 'absolute',
      zIndex: 1,
      left: `calc(50% - ${theme.header.logo.width / 2}px)`,
      display: 'inline-block',
      pointerEvents: 'all',
      transition: `opacity 0.3s ${theme.easings.garret}`,
      '& svg': {
        transition: 'all 0.3s',
        fill: (props) => (props.layoutStyle === 'default' ? theme.colors[1] : theme.colors[3]),
        '& #group': {
          fill: (props) => (props.layoutStyle === 'default' ? theme.colors[1] : theme.colors[4]),
        },
      },
    },
    lang: {
      pointerEvents: 'all',
      position: 'absolute',
      zIndex: 1,
      left: `calc(50% + ${theme.header.logo.width / 2}px)`,
      marginLeft: 150,
      transition: `opacity 0.3s ${theme.easings.garret}`,
      color: (props) => (props.layoutStyle === 'default' ? theme.colors[1] : theme.colors[3]),
    },
    burger: {
      pointerEvents: 'all',
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'all',
      height: '100%',
    },
    burgerLabel: {
      color: (props) => (props.layoutStyle === 'default' ? theme.colors[1] : theme.colors[3]),
      fontSize: 16,
      marginRight: 20,
      cursor: 'pointer',
    },
    burgerButton: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    burgerIcon: {
      color: (props) => (props.layoutStyle === 'default' ? theme.colors[1] : theme.colors[3]),
    },
    burgerButtonOpen: {
      '& $burgerIcon': {
        color: () => theme.colors[1],
      },
    },
    back: {
      pointerEvents: 'all',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: theme.colors[3],
      transition: `all 0.3s ${theme.easings.garret}`,
      '&:hover': {
        '& $backArrow': {
          '&::after': {
            opacity: 1,
          },
        },
      },
    },
    backArrow: {
      position: 'relative',
      zIndex: 1,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '3.5px 4px 3.5px 0',
      borderColor: `transparent ${theme.colors[3]} transparent transparent`,
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      transition: `all 0.3s ${theme.easings.garret}`,
      marginRight: 20,
      '&::after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 30,
        height: 30,
        top: 'calc(50% - 15px)',
        left: 'calc(50% - 13px)',
        background: theme.getRgba(theme.colors[4], 0.4),
        borderRadius: '50%',
        transition: `all 0.75s ${theme.easings.garret}`,
        opacity: 0,
      },
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    payOff: {},
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    lang: {
      marginLeft: 50,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    payOff: {
      display: 'none',
    },
    left: {
      order: 998,
      marginLeft: 'auto',
      marginRight: 15,
    },
    burger: {
      order: 999,
    },
    back: {
      width: 40,
      height: 40,
      background: theme.getRgba(theme.colors[4], 0.25),
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        display: 'none',
      },
    },
    backArrow: {
      marginRight: 0,
    },
    lang: {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 1,
      top: 'calc(50% - 14px)',
      left: 20,
      marginLeft: 0,
      color: () => theme.colors[3],
    },
    burgerLabel: {
      display: 'none',
    },
    logo: {
      position: 'relative',
      left: 'auto',
      width: 140,
      '& svg': {
        width: '100%',
      },
    },
  }

  return output
}

export default style
