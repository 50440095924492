import { memo, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import Overlay from '@/components/Overlay'
import Spinner from '@/components/Spinner'
import ModalDownload from '@/components/ModalDownload'
import Nav from '@/components/Nav'
import SVGSprite from '@/components/SVGSprite'
import useFontPreload from '@/hooks/useFontPreload'
import useScrollTriggerProxy from '@/hooks/useScrollTriggerProxy'
import useChangePathname from '@/hooks/useChangePathname'
import { CONFIG } from '@/constants'
import global from '@/styles/global'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()

  /*------------------------------
  Precache fonts
  ------------------------------*/
  useFontPreload({ fonts: ['Kommissar Web'] })

  /*------------------------------
  Init Scroll Trigger Proxy
  ------------------------------*/
  useScrollTriggerProxy()

  /*------------------------------
  Manage Change Pathname
  ------------------------------*/
  useChangePathname()

  /*------------------------------
  Manage Conditional Reacptcha Wrapper
  ------------------------------*/
  const handleConditionalWrapper = useCallback((child) => (
    <GoogleReCaptchaProvider
      reCaptchaKey={CONFIG.RECAPTCHA_SITE_KEY}
    >
      {child}
    </GoogleReCaptchaProvider>
  ), [])

  /*------------------------------
  Render All Layout Children
  ------------------------------*/
  const renderChildren = useCallback(() => (
    <>
      <div className="wrapper">
        {children}
      </div>
      <SVGSprite />
      <Spinner />
      <Nav />
      <Header />
      <Overlay />
      <ModalDownload />
      <Loader />
    </>
  ), [])

  return (
    <div className={`${classes.root} content-root`}>
      <ConditionalWrapper
        condition={CONFIG.RECAPTCHA_SITE_KEY.length > 0}
        wrapper={handleConditionalWrapper}
        child={renderChildren}
      />
    </div>
  )
}

export default memo(Layout)
