import { memo, useRef, useState } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { cleanOrigin } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const LangSwitcher = () => {
  const classes = useStyles()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentLanguage, languages } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
    languages: state.locale.languages,
  }), shallowEqual)

  const [open, setOpen] = useState(false)
  const $root = useRef()
  const $select = useRef()
  const $options = useRef()

  /*------------------------------
  Click Outside
  ------------------------------*/
  useOnClickOutside($root, () => {
    setOpen(false)
  })

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      ref={$root}
      className={classNames({
        [classes.root]: true,
        open,
      })}
    >
      <button
        className={classes.select}
        ref={$select}
        onClick={() => { setOpen(!open) }}
        aria-label="toggle-lang-switcher"
      >
        {currentLanguage}
        <span className={classes.arrow} />
      </button>
      <div
        className={classes.options}
        ref={$options}
      >
        {Object.values(languages)
          .filter((l) => l.language_code !== currentLanguage)
          .map((lang) => (
            <a
              className={classes.option}
              key={lang.id}
              href={lang.url ? cleanOrigin(lang.url) : `/${lang.language_code}`}
              aria-label={lang.native_name}
            >
              {lang.language_code}
            </a>
          ))}
      </div>
    </div>
  )
}

export default memo(LangSwitcher)
